import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from "docz";
import { Check as CheckIcon } from "@urbaninfrastructure/react-icons";
import { IconButton, Button, ButtonGroup } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "iconbutton"
    }}>{`IconButton`}</h1>
    <p>{`Button with icon in it.`}</p>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<ButtonGroup>\n  <IconButton Icon={CheckIcon}>Checked</IconButton>\n  <IconButton placement=\"left\" Icon={CheckIcon}>\n    Left checked\n  </IconButton>\n  <Button>Regular button</Button>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      CheckIcon,
      IconButton,
      Button,
      ButtonGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup mdxType="ButtonGroup">
    <IconButton Icon={CheckIcon} mdxType="IconButton">Checked</IconButton>
    <IconButton placement="left" Icon={CheckIcon} mdxType="IconButton">
      Left checked
    </IconButton>
    <Button mdxType="Button">Regular button</Button>
  </ButtonGroup>
    </Playground>
    <h2 {...{
      "id": "button-variants"
    }}>{`Button variants`}</h2>
    <Playground __position={1} __code={'<ButtonGroup>\n  <IconButton variant=\"primary\" Icon={CheckIcon}>\n    primary\n  </IconButton>\n  <IconButton variant=\"secondary\" Icon={CheckIcon}>\n    secondary\n  </IconButton>\n  <IconButton variant=\"inverted\" Icon={CheckIcon}>\n    inverted\n  </IconButton>\n  <IconButton variant=\"link\" Icon={CheckIcon}>\n    link\n  </IconButton>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      CheckIcon,
      IconButton,
      Button,
      ButtonGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup mdxType="ButtonGroup">
    <IconButton variant="primary" Icon={CheckIcon} mdxType="IconButton">
      primary
    </IconButton>
    <IconButton variant="secondary" Icon={CheckIcon} mdxType="IconButton">
      secondary
    </IconButton>
    <IconButton variant="inverted" Icon={CheckIcon} mdxType="IconButton">
      inverted
    </IconButton>
    <IconButton variant="link" Icon={CheckIcon} mdxType="IconButton">
      link
    </IconButton>
  </ButtonGroup>
    </Playground>
    <h2 {...{
      "id": "as-anchor"
    }}>{`As anchor`}</h2>
    <Playground __position={2} __code={'<ButtonGroup>\n  <IconButton as=\"a\" href=\"#\" placement=\"left\" Icon={CheckIcon}>\n    As anchor\n  </IconButton>\n  <IconButton\n    as=\"a\"\n    href=\"#\"\n    placement=\"left\"\n    Icon={CheckIcon}\n    variant=\"inverted\"\n  >\n    As inverted anchor\n  </IconButton>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      CheckIcon,
      IconButton,
      Button,
      ButtonGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup mdxType="ButtonGroup">
    <IconButton as="a" href="#" placement="left" Icon={CheckIcon} mdxType="IconButton">
      As anchor
    </IconButton>
    <IconButton as="a" href="#" placement="left" Icon={CheckIcon} variant="inverted" mdxType="IconButton">
      As inverted anchor
    </IconButton>
  </ButtonGroup>
    </Playground>
    <h2 {...{
      "id": "buttons-without-text"
    }}>{`Buttons without text`}</h2>
    <Playground __position={3} __code={'<div>\n  <IconButton rounded Icon={CheckIcon} aria-label=\"check\" />\n  <IconButton rounded variant=\"primary\" Icon={CheckIcon} aria-label=\"check\" />\n  <IconButton\n    rounded\n    variant=\"inverted\"\n    Icon={CheckIcon}\n    aria-label=\"check\"\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      CheckIcon,
      IconButton,
      Button,
      ButtonGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <IconButton rounded Icon={CheckIcon} aria-label="check" mdxType="IconButton" />
    <IconButton rounded variant="primary" Icon={CheckIcon} aria-label="check" mdxType="IconButton" />
    <IconButton rounded variant="inverted" Icon={CheckIcon} aria-label="check" mdxType="IconButton" />
  </div>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={IconButton} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      